import { useOktaAuth } from '@okta/okta-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { RccEvents } from 'src/classes/event-emitter';
import { SnackbarContextProvider } from 'src/components/common/SnackBar/SnackBar';
import { OrganizationContextProvider } from 'src/components/organization/OrganizationProviders/OrganizationProvider';
import { ApigeeGateways, Global, GlobalEmitter, IDMUrlsByEnv } from 'src/constants/global';
import { IRccContext, RccContext } from 'src/contexts/RccContext';
import { useGetOktaUser } from 'src/fetchers/okta.fetchers';
import { useDeleteUserEffectiveOrganizationsCache } from 'src/fetchers/organization.fetchers';
import { RccContextConfig, RccEventKeys } from 'src/types';
import { localStorageProvider } from 'src/utils/local-storage.utils';
import { isInternalUser } from 'src/utils/user.utils';
import { SWRConfig } from 'swr';
import { OrganizationsCacheProvider } from '../organizations-cache/OrganizationsCacheProvider';
import { RccCacheOperator } from './RccCacheOperator';
import { RccLanguageOperator } from './RccLanguageOperator';
import './RccStyles.css';
import { UserPreferencesProvider } from '../user-settings/preferences/UserPreferencesProvider';

const listener = new RccEvents(GlobalEmitter);

export const RccContextProvider = ({
    config,
    children,
}: {
    config: RccContextConfig;
    children: ReactNode;
}) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
    const { userInfo, refreshUserInfo } = useGetOktaUser();
    const deleteCache = useDeleteUserEffectiveOrganizationsCache();

    Global.Services = ApigeeGateways[config.environment];
    Global.IdmUrl = IDMUrlsByEnv[config.environment];
    Global.Environment = config.environment;

    useEffect(
        function authenticate() {
            const accessToken = authState?.accessToken?.accessToken;
            const isAuthenticated = authState?.isAuthenticated && !!accessToken;
            Global.AccessToken = isAuthenticated ? accessToken : '';
            setIsAuthenticated(isAuthenticated);
        },
        [authState, setIsAuthenticated],
    );

    useEffect(
        function autoLogout() {
            const enabled = config?.enableAutoLogout ?? true;
            const onExpire = async () => {
                if (enabled) {
                    await oktaAuth.signOut();
                    GlobalEmitter.emit(RccEventKeys.SIGNOUT);
                }
                await deleteCache().catch(() => {
                    /**
                     * MARKET-7565 we don't want to prevent Okta from logging out
                     * The cache deletion is a nice to have but it should be auto
                     * removed from backend jobs if not done immediately.
                     */
                });
            };

            oktaAuth.tokenManager.on('expired', onExpire);

            return () => {
                oktaAuth.tokenManager.off('expired', onExpire);
            };
        },
        [oktaAuth.tokenManager, config.enableAutoLogout],
    );

    const contextValue: IRccContext = useMemo(
        () => ({
            isAuthenticated,
            events: listener,
            isInternalUser: isInternalUser(userInfo),
            userInfo,
            _TEMPORARY_globalProfileOktaSdkEnabled: config?._TEMPORARY_globalProfileOktaSdkEnabled,
            _TEMPORARY_globalProfilePasswordResetEnabled:
                config._TEMPORARY_globalProfilePasswordResetEnabled,
            refreshUserInfo,
        }),
        [
            isAuthenticated,
            userInfo,
            refreshUserInfo,
            config?._TEMPORARY_globalProfileOktaSdkEnabled,
            config?._TEMPORARY_globalProfilePasswordResetEnabled,
        ],
    );

    return (
        <RccContext.Provider value={contextValue}>
            <SWRConfig
                value={{
                    provider: localStorageProvider,
                    keepPreviousData: true,
                    revalidateOnFocus: false,
                    errorRetryCount: 0,
                }}
            >
                <RccLanguageOperator language={config.language} />
                <RccCacheOperator isAuthenticated={isAuthenticated} />
                <OrganizationsCacheProvider>
                    <SnackbarContextProvider>
                        <UserPreferencesProvider>
                            <OrganizationContextProvider>{children}</OrganizationContextProvider>
                        </UserPreferencesProvider>
                    </SnackbarContextProvider>
                </OrganizationsCacheProvider>
            </SWRConfig>
        </RccContext.Provider>
    );
};
