import { useContext, useEffect } from "react";
import { ThemeContext } from "../components/providers/ThemeProvider";
import { useUserPreferencesContext } from "@ncr-voyix-commerce/react-common-components";

export const useConfigureUserPreferences = () => {
  const { userPreferences } = useUserPreferencesContext();
  const { setColorMode } = useContext(ThemeContext);

  useEffect(() => {
    setColorMode(userPreferences.theme);
  }, [userPreferences.theme, setColorMode]);
};
