import { DisplaySettings } from '@mui/icons-material';
import { SettingsPanelBox } from 'src/components/user-settings/common/SettingsPanel';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ThemePreferences } from './Theme/ThemePreferences';

export const DisplayPreferences = () => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.PREFERENCES.DISPLAY',
    });

    return (
        <SettingsPanelBox title={t('TITLE')} icon={<DisplaySettings />}>
            <ThemePreferences />
        </SettingsPanelBox>
    );
};
