import { RccEventEmitter } from 'src/classes/event-emitter';
import { AppEnv, ApplicationModel, RccEnvironment, ServiceTypeEndpoints } from '../types';

export const Global: AppEnv = {
    Services: null,
    AccessToken: '',
    NepOrganization: '',
    IdmUrl: null,
    Environment: null,
};

export const IdentityAppName = 'IDENTITY';
export const HomeAppName = 'HOME';

type RccEnvironmentSubset = Extract<RccEnvironment, 'dev' | 'stg' | 'prd' | 'local'>;

export const IDMUrlsByEnv: Record<RccEnvironmentSubset, string> = {
    local: 'https://id-dev.ncrcloud.com',
    dev: 'https://id-dev.ncrcloud.com',
    stg: 'https://id-stg.ncrcloud.com',
    prd: 'https://identity.ncr.com',
};

export const HomeApp: Record<RccEnvironmentSubset, ApplicationModel> = {
    local: {
        name: 'HOME',
        organizationId: '',
        displayName: 'Home',
        shortDescription: 'Application for Home',
        idpClientId: '',
        personaManager: false,
        redirectUrl: 'https://home-dev.ncrcloud.com/',
        baseUrl: 'https://home-dev.ncrcloud.com/',
    },
    dev: {
        name: 'HOME',
        organizationId: '',
        displayName: 'Home',
        shortDescription: 'Application for Home',
        idpClientId: '',
        personaManager: false,
        redirectUrl: 'https://home-dev.ncrcloud.com/',
        baseUrl: 'https://home-dev.ncrcloud.com/',
    },
    stg: {
        name: 'HOME',
        organizationId: '',
        displayName: 'Home',
        shortDescription: 'Application for Home',
        idpClientId: '',
        personaManager: false,
        redirectUrl: 'https://home-stg.ncrcloud.com/',
        baseUrl: 'https://home-stg.ncrcloud.com/',
    },
    prd: {
        name: 'HOME',
        organizationId: '',
        displayName: 'Home',
        shortDescription: 'Application for Home',
        idpClientId: '',
        personaManager: false,
        redirectUrl: 'https://home-prd.ncrcloud.com/',
        baseUrl: 'https://home-prd.ncrcloud.com/',
    },
};

export const ApigeeGateways: Record<RccEnvironment, ServiceTypeEndpoints> = {
    local: {
        Provisioning: 'https://gateway-dev-x.ncrcloud.com/provisioning',
        Applications: 'https://localhost:4001/api/proxy/applications',
        FrontDoor: 'https://localhost:4001/api',
        Users: 'https://gateway-dev-x.ncrcloud.com/users',
    },
    dev: {
        Provisioning: 'https://gateway-dev-x.ncrcloud.com/provisioning',
        Applications: 'https://home-dev.ncrcloud.com/api/proxy/applications',
        FrontDoor: 'https://home-dev.ncrcloud.com/api',
        Users: 'https://gateway-dev-x.ncrcloud.com/users',
    },
    stg: {
        Provisioning: 'https://gateway-staging-x.ncrcloud.com/provisioning',
        Applications: 'https://home-stg.ncrcloud.com/api/proxy/applications',
        FrontDoor: 'https://home-stg.ncrcloud.com/api',
        Users: 'https://gateway-staging-x.ncrcloud.com/users',
    },
    prd: {
        Provisioning: 'https://api.ncr.com/provisioning',
        Applications: 'https://home-prd.ncrcloud.com/api/proxy/applications',
        FrontDoor: 'https://home-prd.ncrcloud.com/api',
        Users: 'https://api.ncr.com/users',
    },
    napac: {
        Provisioning: 'https://api-napac.ncr.com/provisioning',
        Applications: 'https://home-napac.ncrcloud.com/api/proxy/applications',
        FrontDoor: 'https://home-napac.ncrcloud.com/api',
        Users: 'https://api-napac.ncr.com/users',
    },
    apac: {
        Provisioning: 'https://api-apac.ncr.com/provisioning',
        Applications: 'https://home-apac.ncrcloud.com/api/proxy/applications',
        FrontDoor: 'https://home-apac.ncrcloud.com/api',
        Users: 'https://api-apac.ncr.com/users',
    },
    emea: {
        Provisioning: 'https://api-emea.ncr.com/provisioning',
        Applications: 'https://home-emea.ncrcloud.com/api/proxy/applications',
        FrontDoor: 'https://home-emea.ncrcloud.com/api',
        Users: 'https://api-emea.ncr.com/users',
    },
};

export const GlobalEmitter = new RccEventEmitter();
