import {
  ApplicationsContextProvider,
  RccContextConfig,
  RccContextProvider,
} from "@ncr-voyix-commerce/react-common-components";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { Outlet, useNavigate } from "react-router-dom";
import "./AppLayout.css";
import { AppShell } from "./components/app-shell/AppShell";
import { DemoAppBar } from "./components/navigation/DemoAppBar";
import { ThemeProvider } from "./components/providers/ThemeProvider";
import { IdentityAppName } from "./constants/constants";
import { oktaAuthOptions } from "./constants/okta-auth-config";

const rccConfig: RccContextConfig = {
  language: "en",
  environment: window._env_.REACT_APP_ENVIRONMENT,
  _TEMPORARY_globalProfileOktaSdkEnabled: true,
  _TEMPORARY_globalProfilePasswordResetEnabled: false,
};

const oktaAuth = new OktaAuth(oktaAuthOptions);

function AppLayout() {
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "", window.location.origin), {
      replace: true,
    });
  };

  return (
    <div className="App">
      <ThemeProvider>
        <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
          <RccContextProvider config={rccConfig}>
            <ApplicationsContextProvider defaultApp={IdentityAppName}>
              <DemoAppBar />
              <AppShell>
                <Outlet />
              </AppShell>
            </ApplicationsContextProvider>
          </RccContextProvider>
        </Security>
      </ThemeProvider>
    </div>
  );
}

export default AppLayout;
