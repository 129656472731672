export type RecentOrganization = {
    organizationName: string,
    displayName: string
}

export type UpdateRecentOrganizationsDto = {
    recentOrganizations: RecentOrganization[]
}

export enum UserPreferencesTheme {
    LIGHT = 'light',
    DARK = 'dark',
}

export type UserPreferences = {
    username: string,
    theme: UserPreferencesTheme,
    defaultOrganizationId?: string,
}

export type UpdateUserPreferences = Partial<Omit<UserPreferences, 'username'>>;