import { MenuItem, Typography } from '@mui/material';
import { MutableRefObject } from 'react';
import { SwitcherMenu } from 'src/components/common/Switcher/SwitcherMenu';
import { AppConstants } from 'src/constants/app';
import { ThemeValues } from 'src/constants/theme-values';
import { useOrganizationContext } from 'src/contexts';
import { useOrganizationsCacheContextInternal } from 'src/contexts/OrganizationsCacheContext';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Organization } from 'src/types';
import { RecentOrganization } from 'src/types/user-preferences.type';

export const OrganizationSwitcherMenu = ({
    anchorRef,
    menuOpen,
    onMenuClose,
    onOrgClick,
}: {
    anchorRef: MutableRefObject<HTMLButtonElement | null>;
    menuOpen: boolean;
    onMenuClose: () => void;
    onOrgClick: (orgName: string) => void;
}) => {
    const { t } = useTranslationNs();
    const { userOrganizations, setIsOrgDialogOpen, organization } = useOrganizationContext();
    const { recentOrganizations } = useOrganizationsCacheContextInternal();
    const recentsEnabled = recentOrganizations?.length > 0;

    const handleViewAll = () => {
        onMenuClose();
        setIsOrgDialogOpen(true);
    };
    const orgList: RecentOrganization[] | Organization[] = recentsEnabled
        ? recentOrganizations
        : userOrganizations;

    const effectiveOrgs = orgList.slice(0, AppConstants.OrgMenuMax).map((org: Organization) => {
        const orgName = org.organizationName;

        return (
            <MenuItem
                sx={{
                    padding: ThemeValues.MenuItemPadding,
                }}
                selected={orgName === organization?.organizationName}
                key={orgName}
                onClick={() => onOrgClick(orgName)}
            >
                <Typography variant='body1'>{orgName}</Typography>
            </MenuItem>
        );
    });

    return (
        <SwitcherMenu
            anchorRef={anchorRef}
            ctaTitle={t('ORG_CONTEXT.MENU.MANAGE_ALL_ORGS')}
            menuTitle={t('ORG_CONTEXT.MENU.TITLE')}
            menuOpen={menuOpen}
            onCtaClick={handleViewAll}
            onMenuClose={onMenuClose}
        >
            {effectiveOrgs}
        </SwitcherMenu>
    );
};
